import React from "react";

export const Videos = ({ videos }) => (
  <div className="videos">
    <div className="videos__videos">
      {videos.map((film, index) => (
        <iframe
          src={film.http}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          title="first-video"
          className="videos__video"
        />
      ))}
    </div>
  </div>
);
