import React from "react";
import { useSelector } from "react-redux";
import newLogo from "../assets/newLogo.png";

export const Curators = () => {
  const initiativeLogos = useSelector((state) => state.main.initiativeLogos);
  const curators = useSelector((state) =>
    state.main.curators.filter((curator) => {
      if (window.innerWidth <= 970) return curator.name.length > 0;
      return curator;
    })
  );  
  return (
    <>
      <div className="section">
        <div className="curators-decor">
          <div className="curators-decor__decor">
            <div className="curators-decor__ornament curators-decor__ornament_left" />
            <div className="curators-decor__decor-text">куратори проекту</div>
            <div className="curators-decor__ornament curators-decor__ornament_right" />
          </div>
        </div>

        <div className="curators-decor__decor">
          <div>
            <div className="curators-decor__newornament curators-decor__ornament_left" />
            <div className="curators-decor">
              <a
                href="https://www.facebook.com/culturalcapital2021/"
                target="_blank"
                rel="noreferrer"
                key="newLogo"
                className="initiative-logos__link"
              >
                <img
                  src={newLogo}
                  alt="newLogo"
                  className="curators-decor__newlogo"
                />
              </a>
            </div>
          </div>
          <div className="curators">
            {curators.map((curator) => (
              //  window.innerWidth <=970 ?
              <div key={curator.id} className="curator">
                <img src={curator.img} alt="curator" className="curator__img" />
                <div className="curator__name-wrap">
                  <a
                    href={curator.href}
                    target="_blanl"
                    rel="noreferrer"
                    className="curator__name"
                  >
                    {curator.name}
                  </a>
                </div>
                <div className="curator__position">{curator.position}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="initiative-logos">
        {initiativeLogos.map((logo) => (
          <a
            href={logo.href}
            target="_blank"
            rel="noreferrer"
            key={logo.id}
            className="initiative-logos__link"
          >
            <img src={logo.img} alt="logo" className="initiative-logos__item" />
          </a>
        ))}
      </div>
    </>
  );
};
