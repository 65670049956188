import * as React from "react";
import { connect, useSelector } from "react-redux";

import { deleteEvent } from "../../store/actions/eventActions";

const Event = ({ event, deleteEvent }) => {
  const auth = useSelector((state) => state.firebase.auth);  
  return (
    <div className="events">
      <div className="event">
        <div className="event__img-container">
          <img src={event.image} alt="event poster" className="event__img" />
        </div>
        <div className="event__info">
          <div className="event__title">{event.title}</div>
          <div className="event__desc">{event.description}</div>
          <a
            href={event.url}
            target="_blank"
            rel="noreferrer"
            className="event__link"
          >
            Посилання на подію
          </a>
        </div>
        {auth.uid ? (
          <div className="event__delete">
            <ion-icon
              name="close-outline"
              onClick={() => deleteEvent(event.megaId)}
            />
          </div>
        ) : null}
      </div>
      {event.videoUrl &&
        event.videoUrl != undefined &&
        event.videoUrl[0].length >= 1 &&
        (event.videoUrl.length == 2 ? (
          <div className="events__videos">
            <iframe
              src={event.videoUrl[0]}
              className="events__video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              src={event.videoUrl[1]}
              className="events__video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        ) : (
          <div className="events__videos">
            <iframe
              src={event.videoUrl}
              className="events__video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        ))}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteEvent: (id) => dispatch(deleteEvent(id)),
  };
};

export default connect(null, mapDispatchToProps)(Event);
