import React from "react";
import { useSelector } from "react-redux";

import mainLogo from "../assets/main_logo.svg";

import { Jumbotron } from "../components/Jumbotron";
import { Nav } from "../components/Nav";
import { Calendar } from "../components/Calendar.jsx";
import { Videos } from "../components/Videos";

export const Header = () => {
  const content = useSelector((state) =>
    state.main.initiatives.find((item) => item.section === "Header")
  );

  return (
    <div className="header">
      <img src={mainLogo} alt="logo" className="header__logo" />
      <Nav />
      <Calendar />
      <Jumbotron
        title={content.title}
        desc={content.desc}
        btn={content.btn}
        moreBtn={content.moreBtn}
        background={content.background}
        titleColor={content.titleColor}
        descColor={content.descColor}
      />

      {content.video && content.video != undefined && (
        <Videos videos={content.video} />
      )}
      <div className="header__decor">
        <div className="header__ornament header__ornament_left" />
        <div className="header__decor-text">
          За підтримки українського культурного фонду
        </div>
        <div className="header__ornament header__ornament_right" />
      </div>
    </div>
  );
};
